
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import AddressModule from '@/store/modules/address'
import httpHelper from '@/utils/request'
import RegionPicker from '@/components/RegionPicker.vue'
import { isMobile } from '@/utils/validate'
import GlobalMixin from '@/mixins/global'

const w = window as any
const AMap = w.AMap

@Component({
  name: 'addressedit',
  components: {
    'region-picker': RegionPicker
  },
  mixins: [GlobalMixin]
})
export default class AddressEdit extends Vue {
  @Provide() private id: number = -1
  @Provide() private showAreaPicker: boolean = false
  @Provide() private addressInfo: string = ''

  @Provide() private receiveContact: string = ''
  @Provide() private receiveCellPhone: string = ''
  @Provide() private receiveAddress: string = ''
  @Provide() private receiveAreaName: string = ''
  @Provide() private isDefault: boolean = false
  @Provide() private type: number = 1
  @Provide() private position: any = {}
  @Provide() private loading: boolean = false
  @Provide() private receiveAreaSysNo: number = 0
  @Provide() private dialog: boolean = false
  @Provide() private showAmapResult: boolean = false
  @Provide() private amapResult: any = []
  @Provide() private selected: boolean = false
  @Provide() private isFormOrder: boolean = false
  @Provide() private streetSysNo: number = 0
  @Provide() private street: string = ''
  @Provide() private differentDialog:boolean = false
  @Provide() private differentPosition:any = {}
  @Provide() private differentTip: String = ''
  @Provide() private checkDialog:boolean = false
  @Provide() private checkTip: String = ''
  @Provide() timer: any = null

  @Watch('$route')
  private currentRouteChanged () {
    const { id } = this.$route.params
    const { type } = this.$route.query
    this.id = id ? parseInt(id) : -1
    this.isFormOrder = (type === 'order')
  }

  @Watch('showAreaPicker')
  private watchShowAreaPicker () {
    // if (this.showAreaPicker) {
    //   document.body.className = 'ovfHiden'
    // }
    this.showAreaPicker ? document.body.classList.add('ovfHiden') : document.body.classList.remove('ovfHiden')
  }

  private async created () {
    await AddressModule.loadAddresses()
    this.currentRouteChanged()
    const addresses: any = AddressModule.addresses || []
    const address = addresses && this.id ? addresses.find((a: any) => a.addressSysNo === this.id) : {}
    const { addressSysNo = -1, receiveContact = '', receiveAreaSysNo = 0, streetSysNo = 0, receiveCellPhone = '', receiveAreaName = '', receiveAddress = '', isSelected = '0', addressType = '1' } = address || {}

    this.receiveContact = receiveContact
    this.receiveCellPhone = receiveCellPhone
    this.receiveAddress = receiveAddress
    this.receiveAreaName = receiveAreaName
    this.isDefault = isSelected === '1'
    this.type = parseInt(addressType)
    this.id = addressSysNo
    this.receiveAreaSysNo = receiveAreaSysNo
    this.streetSysNo = streetSysNo
    this.street = receiveAreaName.split(' ')[3] || ''

    if (addressSysNo) {
      this.formatAddress()
    }
  }

  private mounted () {
    window.addEventListener('click', this.hideAmapResult, false)
  }

  private beforeDestroy () {
    window.removeEventListener('click', this.hideAmapResult)
  }

  private async formatAddress () {
    if (!this.receiveAreaName) {
      return
    }
    try {
      const res = await httpHelper.get({
        // url: 'Address/Format',
        url: 'Region/area',
        data: {
          // address: this.receiveAreaName
          sysNo: this.receiveAreaSysNo
        },
        type: 'unify'
      })
      const result = res.value || {}
      this.position = {
        province: {
          provinceSysNo: result.provinceSysNo,
          provinceName: result.province
        },
        city: {
          citySysNo: result.citySysNo,
          cityName: result.city
        },
        area: {
          districtSysNo: result.districtSysNo,
          districtName: result.district
        },
        street: {
          streetSysNo: this.streetSysNo,
          streetName: this.street
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  get validate (): boolean {
    if (!this.receiveContact) {
      return false
    }
    if (this.receiveContact.length > 10) {
      return false
    }
    if (!this.receiveCellPhone) {
      return false
    }
    if (!isMobile(this.receiveCellPhone)) {
      return false
    }
    if (!this.position || !this.position.street || !this.position.street.streetName) {
      return false
    }
    if (!this.receiveAddress) {
      return false
    }
    if (this.receiveAddress.length > 25) {
      return false
    }
    return true
  }

  get title () {
    return this.id === 0 ? '新建地址' : '修改地址'
  }

  get area (): string {
    if (!this.position) {
      return ''
    }
    const province = this.position.province
    const city = this.position.city
    const area = this.position.area
    const street = this.position.street

    if (!province) {
      return ''
    }
    return `${province ? province.provinceName : ''} ${city ? city.cityName : ''} ${area ? area.districtName : ''} ${street ? (street.streetName || '') : ''}`
  }

  @Emit()
  public updateArea (position: any) {
    this.position = position
    this.showAreaPicker = false
  }

  @Emit()
  private async goBack () {
    this.$router.back()
  }

  @Emit()
  public async submit () {
    const receiveContact = this.receiveContact.trim()
    const receiveCellPhone = this.receiveCellPhone.trim()
    const receiveAddress = this.receiveAddress.trim()
    const { area = {} } = this.position || {}
    if (!receiveContact) {
      this.$toasted.show('收件人不能为空')
      return
    }
    if (receiveContact.length > 10) {
      this.$toasted.show('收件人名称过长')
      return
    }
    if (!receiveCellPhone) {
      this.$toasted.show('手机号不能为空')
      return
    }
    if (!isMobile(receiveCellPhone)) {
      this.$toasted.show('手机号不符合规范')
      return
    }
    if (!area.districtSysNo && !area.districtName) {
      this.$toasted.show('请填写收货人所在省、市、区县、街道')
      return
    }
    if (!receiveAddress) {
      this.$toasted.show('详细地址不能为空')
      return
    }
    this.checkAddress()
  }

  @Emit()
  public async editAddress () {
    this.position = this.differentPosition
    this.saveAddressRequest()
  }

  @Emit()
  public async saveAddressRequest () {
    const { area, street } = this.position
    const receiveContact = this.receiveContact.trim()
    const receiveCellPhone = this.receiveCellPhone.trim()
    const receiveAddress = this.receiveAddress.trim()
    try {
      const res = await httpHelper.post({
        url: 'IUserHome/UpdateAddress',
        data: {
          receiveContact: receiveContact,
          receiveAddress: receiveAddress,
          receiveCellPhone: receiveCellPhone,
          isDefault: this.isDefault ? 1 : 0,
          addressType: this.type,
          addressSysno: this.id,
          receiveAreaSysNo: area.districtSysNo,
          streetSysNo: street.streetSysNo || 0,
          streetName: street.streetName || ''
        },
        type: 'apiv5'
      })
      AddressModule.loadAddresses()
      const addressSysNo = res.data.addressSysNo
      AddressModule.setAddress(addressSysNo)
      this.$toasted.show('地址已保存成功')
      this.$router.back()
    } catch (err) {
      const msg = err.message || '保存失败，请重新尝试'
      this.$toasted.show(`${msg}`)
    }
  }

  @Emit()
  private clearAddress () {
    this.addressInfo = ''
  }

  @Emit()
  private async inputAddressInfo (e: any) {
    const value = e.target.value
    if (value.length >= 100) {
      this.$toasted.show('最多支持输入100个字')
    }
  }

  @Emit()
  private async searchAddress (e: any) {
    const { city } = this.position
    if (this.selected) {
      return
    }
    this.selected = false
    if (!city || !city.cityName) {
      return
    }
    this.debounce(this.fetchAmapAssociation, 400)
  }

  private fetchAmapAssociation () {
    const { city } = this.position
    const self = this
    AMap.plugin('AMap.Autocomplete', () => {
      const autocomplete = new AMap.Autocomplete({
        city: city.cityName,
        citylimit: true,
        datatype: 'poi'
      })
      autocomplete.search(self.receiveAddress, (status: string, info: any) => {
        console.log(status, info)
        self.showAmapResult = info.count > 0
        self.amapResult = info.tips || []
      })
    })
  }

  private debounce (fn: Function, delay: number) {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      fn()
    }, delay)
  }

  private hideAmapResult () {
    this.showAmapResult = false
    this.selected = false
  }

  @Emit()
  private selectAddress (ad: string) {
    this.receiveAddress = ad
    this.selected = true
    this.showAmapResult = false
  }

  @Emit()
  private async deleteAddress () {
    try {
      await httpHelper.post({
        url: 'IUserHome/DeleteAddress',
        data: {
          sysNo: this.id
        },
        type: 'apiv5'
      })
      this.$router.back()
    } catch (err) {
      console.log(err)
    }
  }

  @Emit()
  private async autoIdentified () {
    if (!this.addressInfo) {
      return
    }
    this.loading = true
    try {
      const res = await httpHelper.get({
        url: 'Address/Analysis',
        data: {
          address: encodeURIComponent(this.addressInfo)
        },
        type: 'unify'
      })
      const result = res.value || {}
      const { receiverName = '', phone = '', provinceName = '', cityName = '', districtName = '', streetName = '', addressName = '', provinceSysno = 0, citySysno = 0, districtSysno = 0, streetSysNo = 0 } = result

      this.receiveContact = receiverName
      this.receiveCellPhone = phone
      this.receiveAddress = addressName
      this.position = {
        province: {
          provinceSysNo: provinceSysno,
          provinceName: provinceName
        },
        city: {
          citySysNo: citySysno,
          cityName: cityName
        },
        area: {
          districtSysNo: districtSysno,
          districtName: districtName
        },
        street: {
          streetSysNo: streetSysNo,
          streetName: streetName
        }
      }
    } catch (err) {
      const msg = err.msg || '识别失败，请完善收货人信息'
      this.$toasted.show(`${msg}`)
    } finally {
      this.loading = false
    }
  }

  @Emit()
  private async checkAddress () {
    this.loading = true
    try {
      const { area, street } = this.position
      const receiveAddress = this.receiveAddress.trim()
      const res = await httpHelper.get({
        url: 'IAddress/CheckAddressArea',
        data: {
          address: receiveAddress,
          areaSysNo: area.districtSysNo,
          streetSysNo: street.streetSysNo
        },
        type: 'apiv5'
      })
      const bean = res.data || {}
      switch (bean.type) {
        case 0:
          await this.saveAddressRequest()
          break
        case 1:
          this.changeDialogContent(bean)
          this.position = {
            province: {
              provinceSysNo: bean.area.provinceSysNo,
              provinceName: bean.area.provinceName
            },
            city: {
              citySysNo: bean.area.citySysNo,
              cityName: bean.area.cityName
            },
            area: {
              districtSysNo: bean.area.districtSysNo,
              districtName: bean.area.districtName
            },
            street: {
              streetSysNo: bean.area.streetSysNo,
              streetName: bean.area.streetName
            }
          }
          this.checkDialog = true
          break
        case 2:
          this.differentTip = bean.tips
          this.differentDialog = true
          this.differentPosition = {
            province: {
              provinceSysNo: bean.area.provinceSysNo,
              provinceName: bean.area.provinceName
            },
            city: {
              citySysNo: bean.area.citySysNo,
              cityName: bean.area.cityName
            },
            area: {
              districtSysNo: bean.area.districtSysNo,
              districtName: bean.area.districtName
            },
            street: {
              streetSysNo: bean.area.streetSysNo,
              streetName: bean.area.streetName
            }
          }
          break
      }
    } catch (e) {
      this.$toasted.show(`${e}`)
    } finally {
      this.loading = false
    }
  }

  private changeDialogContent (bean: any) {
    // 先替换新的
    let replaceReg = new RegExp(bean.area.streetName, 'g')
    let replaceContent = '<span style="color: #FF0000">' + bean.area.streetName + '</span>'
    if (bean.tips.includes(bean.area.streetName)) {
      bean.tips = bean.tips.replace(replaceReg, replaceContent)
    }
    // 再替换旧的
    let replaceRegO = new RegExp(bean.originArea.streetName, 'g')
    let replaceContentO = '<span style="color: #FF0000">' + bean.originArea.streetName + '</span>'
    if (bean.tips.includes(bean.originArea.streetName)) {
      bean.tips = bean.tips.replace(replaceRegO, replaceContentO)
    }
    this.checkTip = bean.tips
  }

  @Emit()
  private inputCellPhone (e: any) {
    const value = e.target.value || ''
    this.receiveCellPhone = value.replace(/[, ]/g, '')
  }
}
