
import { Component, Prop, Vue, Provide, Emit, Watch } from 'vue-property-decorator'
import AddressModule from '@/store/modules/address'
import httpHelper from '@/utils/request'

@Component({
  components: {
  }
})
export default class RegionPicker extends Vue {
  @Provide() public p: any = {}
  @Provide() public c: any = {}
  @Provide() public a: any = {}
  @Provide() public s: any = {}
  @Provide() public level: number = 1
  @Provide() public requestFailed: boolean = false
  @Provide() public addressOptions: any[] = []

  @Provide() public provinceOptions: any = {}
  @Provide() public cityOptions: any = {}
  @Provide() public areaOptions: any = {}
  @Provide() public streetOptions: any = {}

  @Prop({
    type: Number,
    default: 3
  }) public mustLevel!: number

  @Prop({
    type: Object,
    default: undefined
  }) public position!: any

  public created () {
    this.loadAddress()
    const { province: p = {}, city: c = {}, area: a = {},
      street: s = {} } = this.position
    this.p = p
    this.c = c
    this.a = a
    this.s = s
    // debugger
    if (s.streetSysNo !== undefined && s.streetSysNo !== null) {
      this.level = 4
      this.loadStreetList(a.districtSysNo)
    } else if (a.districtSysNo) {
      this.loadStreetList(a.districtSysNo)
      this.level = 3
    } else if (c.citySysNo) {
      this.level = 2
    } else {
      this.level = 1
    }
  }

  private async loadAddress () {
    try {
      const res = await httpHelper.get({
        url: 'IUserHome/AreaInfo',
        data: {
          // isFromShopping: 0
        },
        type: 'apiv5'
      })
      this.addressOptions = res.data ? res.data.areaInfo : []
      this.provinceOptions = this.addressOptions
      this.requestFailed = false
    } catch (err) {
      this.requestFailed = true
      console.log(err)
    }
  }

  private async loadStreetList (sysNo: number) {
    let districtSysno = 0
    if (this.position.districtSysno) {
      districtSysno = this.position.districtSysno
    } else {
      districtSysno = this.a.districtSysNo
    }
    try {
      const res = await httpHelper.get({
        url: 'IUserHome/Street',
        data: {
          districtSysno: sysNo
        },
        type: 'apiv5'
      })
      this.streetOptions = res.data || []
      this.requestFailed = false
    } catch (err) {
      this.requestFailed = true
      console.log(err)
    }
  }

  @Emit()
  public async tapOption (option: any, level: number) {
    if (!option) {
      return
    }
    const self = this
    function updateArea () {
      const position: any = {
        province: self.p,
        city: self.c,
        area: self.a,
        street: self.s
      }
      self.$emit('updateArea', position)
    }
    switch (level) {
      case 1:
        this.level = level + 1
        this.cityOptions = option.citys
        this.p = {
          provinceSysNo: option.provinceSysno,
          provinceName: option.provinceName
        }
        this.c = {}
        this.a = {}
        this.s = {}
        break
      case 2:
        this.level = level + 1
        this.areaOptions = option.districts
        this.c = {
          citySysNo: option.citySyso,
          cityName: option.cityName
        }
        this.a = {}
        this.s = {}
        break
      case 3:
        this.level = level + 1
        this.a = {
          districtSysNo: option.sysno,
          districtName: option.districtname
        }
        this.s = {}
        this.loadStreetList(option.sysno)
        if (this.mustLevel === 3) {
          updateArea()
        }
        break
      case 4:
        this.s = {
          streetSysNo: option.sysNo,
          streetName: option.streetName
        }
        if (this.mustLevel === 4) {
          updateArea()
        }
    }
  }

  @Emit()
  public async tapTab (level: number) {
    const { p: province, c: city, a: area, s: street } = this
    this.level = level
    switch (level) {
      case 1:
        this.provinceOptions = this.addressOptions
        break
      case 2:
        if (province && province.provinceSysNo) {
          this.addressOptions.forEach((item: any) => {
            if (Number(item.provinceSysno) === Number(province.provinceSysNo)) {
              this.cityOptions = item.citys
            }
          })
        } else {
          this.cityOptions = this.addressOptions[0].citys
        }
        break
      case 3:
        if (city && city.citySysNo) {
          this.addressOptions.forEach((item: any) => {
            if (Number(item.provinceSysno) === Number(province.provinceSysNo)) {
              item.citys.forEach((cityItem: any) => {
                if (Number(cityItem.citySyso) === Number(city.citySysNo)) {
                  this.areaOptions = cityItem.districts
                }
              })
            }
          })
        } else {
          this.areaOptions = this.addressOptions[0].districts
        }
        break
      case 4:
        if (area && area.districtSysNo) {
          this.loadStreetList(area.districtSysNo)
        } else {
          this.loadStreetList(0)
        }
    }
  }

  @Emit()
  private close () {
    this.$emit('close')
  }

  @Emit()
  private handleSysNo (sysNo: number) {
    return Number(sysNo)
  }
}
